import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import Loadable from "@loadable/component"
import "./NewsLetter.scss";
const NewsletterFormPage = Loadable(() => import("../../forms/newsletter-form"))

// markup

const NewsLetter = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      siteConfig {
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Youtube_Link
      }
    }
  }
`);
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(()=>{
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("keypress", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })

  
  })
  const site = data.glstrapi.siteConfig
  return (
    <React.Fragment> 
              <section className="news-letter">
                <div className={` ${!props.innerPage ? ' pad-50 featured-properties container' : ''}`}>
                  <div className="newsletter-info">
                    <h3>Sign up for Newsletter</h3>
                    {renderComponent && <NewsletterFormPage />}
                  </div>
                  {!props.innerPage &&
                  <div className="icon-list d-md-none d-xl-block">
                    <ul className="social-icons">
                      {site.Facebook_Link &&
                        <li>
                          <Link href={site.Facebook_Link} target="_blank">
                            <i className="icon-fb"></i>
                          </Link>
                        </li>
                      }
                      <li>
                          <Link href={'https://twitter.com/Dachateam'} target="_blank">
                            <i className="icon-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link href={'https://www.tiktok.com/@dacharealestate?lang=en'} target="_blank">
                            <i className="icon-tiktok"></i>
                          </Link>
                        </li>
                      {site.Instagram_Link &&
                        <li>
                          <Link href={site.Instagram_Link} target="_blank">
                            <i className="icon-insta"></i>
                          </Link>
                        </li>
                      }
                      {site.Youtube_Link &&
                        <li>
                          <Link href={site.Youtube_Link} target="_blank">
                            <i className="icon-youtube"></i>
                          </Link>
                        </li>
                      }
                      {site.Linkedin_Link &&
                        <li>
                          <Link href={site.Linkedin_Link} target="_blank">
                            <i className="icon-linkedln"></i>
                          </Link>
                        </li>
                      }
                    </ul>
                  </div>
                  }
                </div>
              </section> 
    </React.Fragment>
  );
};

export default NewsLetter;
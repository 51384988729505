import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
//import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import "./Contact.scss"
import { GetURL } from "../../common/site/functions"
// markup
const StaticContact = () => {
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
	    globalModule {
            Get_Started_Content
            Get_Started_CTA_1_Label
            Get_Started_CTA_2_Label
            Get_Started_CTA_1_Link {
              URL
              id
              Link_Type
            }
            Get_Started_CTA_2_Link {
              URL
              id
              Link_Type
            }
	    }
      }
    }
  `);



    const content = data.glstrapi.globalModule

    const ctaLink = (type, label, link) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (link.Link_Type === "Internal") {
            return <Link to={`/${GetURL(link.id)}`} className={cls}>{label}</Link>
        } else if (link.Link_Type === "External") {
            return <Link to={`${GetURL(link.id)}`} target="_blank" className={cls}>{label}</Link>
        }
    }
    return (
        <React.Fragment>
            <div animateIn='fadeInUp' delay={0.7} animateOnce className="contact">
                <Container>
                    <Row>
                        <Col>
                            <div className="contact-us">
                                {content.Get_Started_Content &&
                                    HTMLReactParser(content.Get_Started_Content.replace(/<br\s*\/?>/gi, ''))
                                }
                                <div className="btn-wrapper">
                                    {content.Get_Started_CTA_1_Label && content.Get_Started_CTA_1_Link &&
                                        ctaLink(1, content.Get_Started_CTA_1_Label, content.Get_Started_CTA_1_Link)

                                    } 
                                    {content.Get_Started_CTA_2_Label && content.Get_Started_CTA_2_Link &&
                                        ctaLink(2, content.Get_Started_CTA_2_Label, content.Get_Started_CTA_2_Link)
                                    } 
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default StaticContact